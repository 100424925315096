import Slideout from './Modules/Slideout';

Slideout.init();

let marker = document.querySelector('.marker');
let transformStart = 'translateY(44%) rotateZ(45deg)';

setMarkerToSelected();

$('.nav-main > ul > li').each(function() {
  $(this).mouseover(function() {
    moveMarker(this.offsetLeft + this.clientWidth / 2);
  })
  $(this).mouseout(function() {
      setMarkerToSelected();
  })
});

function setMarkerToSelected() {
  let selectedLi = document.querySelector('.nav-main ul .selected');

  if (!selectedLi) {
    selectedLi = document.querySelector('.nav-main ul > li:first-child');
  }

  //-- Move marker to center of select li
  moveMarker(selectedLi.offsetLeft + selectedLi.clientWidth / 2);
}

function moveMarker(left) {
  let translateX = left - marker.offsetLeft - marker.clientWidth / 2;
  marker.style.transform = 'translateX(' + translateX + 'px)' + transformStart;
}
