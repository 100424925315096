import Slideout from 'slideout';

let s, SlideoutObject = {
    settings: {
        slideout: null
    },
    init: function() {
        s = this.settings;
        this.render();
        this.onClick();
        this.onInteract();
    },
    render: function() {
        s.slideout = new Slideout({
            'panel': document.getElementById('panel'),
            'menu': document.getElementById('menu'),
            'padding': 256,
            'tolerance': 70,
            'side': 'right'
        });
    },
    onClick: function() {
        $('#toggle-menu').on('click', function() {
            $(this).toggleClass('is-active');
            s.slideout.toggle();
        });
        $('.toggle-close').on('click', function() {
          $(this).removeClass('is-active');
          s.slideout.close();
        })
    },
    onInteract: function() {
        s.slideout.on('translatestart', function() {
            $('#toggle-menu').toggleClass('is-active');
        });
        s.slideout.on('close', function() {
            $('#toggle-menu').removeClass('is-active');
        });
    }
};

export default SlideoutObject;
